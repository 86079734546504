module.exports = [{
      plugin: require('/codebuild/output/src198316804/src/dalair-ipsum/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"linkImagesToOriginal":false,"wrapperStyle":"margin-bottom: 1.0725rem;"},
    },{
      plugin: require('/codebuild/output/src198316804/src/dalair-ipsum/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-138331404-1"},
    },{
      plugin: require('/codebuild/output/src198316804/src/dalair-ipsum/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src198316804/src/dalair-ipsum/node_modules/gatsby-plugin-react-next/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
